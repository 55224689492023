import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
            primary: '#2399C0',
            yellow: '#F7D842',
            green: '#98CB4A',
            purple: '#BC5AE0',
            orange: '#E8AF1F',
            blue: '#21A4BF',
            pink: '#E053A1',
            },
        },
    },
});
