<template>
  <v-app>

    <header>

      <v-container>

        <v-row class="mt-10 mb-15">

          <v-col>
            <v-img
              src="@/assets/logo_softino.svg"
              max-width=300
              contain
            >
            </v-img>
          </v-col>

          <v-col class="text-right ml-auto d-none d-md-block" cols="auto" align-self="center">

            <div class="menu">
              <span class="mi"
                v-for="(item, i) in menuItems"
                :key="i"
              >
                <v-btn
                  text
                  @click="$vuetify.goTo('#'+item.anchor, {offset: 30})"
                >
                  {{ item.title }}
                </v-btn>
              </span>
              <span class="mi">
                <a href="/en" class="v-btn v-btn--text theme--light v-size--default w-menu">
                  <img src="en.png" alt="English version" title="Switch to English version" />
                </a>
              </span>
            </div>
          </v-col>

          <v-col class="text-right ml-auto d-md-none" cols="auto" align-self="center">
            <v-menu
              bottom
              origin="top center"
              transition="scale-transition"
              offset-y
              left
              nudge-top="-10"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-app-bar-nav-icon color="primary" x-large v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
              </template>
              
              <v-list class="py-3" color="primary" dark>
                <v-list-item class="menu-list px-11"
                  link
                  v-for="(item, i) in menuItems"
                  :key="i"
                >
                  <v-list-item-title @click="$vuetify.goTo('#'+item.anchor, {offset: 30})">{{ item.title }}</v-list-item-title>
                </v-list-item>
                <div class="menu-list px-11 v-list-item v-list-item--link theme--dark">
                  <a href="/en" class="v-btn v-btn--text theme--light v-size--default w-menu">
                    <img src="en.png" alt="English version" title="Switch to English version" />
                  </a>
                </div>
              </v-list>

            </v-menu>
          </v-col>
          
        </v-row>

      </v-container>
    </header>

    <v-main>
      
      <div class="x circle-left white--text">

        <v-container>

          <v-row>
            <v-col cols="8" class="pa-0">
              <div class="text-container pa-md-13 pa-3 pl-md-3">
                <div class="title">
                  <h2 class="font-weight-regular" v-html="leadBox.title"></h2>
                </div>
                <div class="text subtitle-1 mt-5" v-html="leadBox.text">
                </div>
              </div>
            </v-col>
            <v-col cols="4" class="pa-0 text-center">
              <v-icon class="background-xml d-md-inline-flex d-none w-f" color="primary">mdi-xml</v-icon>
            </v-col>
          </v-row>

        </v-container>

      </div>

      <div class="regular my-13">

        <v-container>

          <v-row id="o-nas">
            <v-col cols="4">
              <h2 class="font-weight-regular" v-html="about.title"></h2>
            </v-col>
            <v-col cols="8">
              <div v-html="about.text"></div>
            </v-col>
          </v-row>

        </v-container>

      </div>

      <div class="circle-right white--text">

        <v-container>

          <v-row id="nas-tym">
            <v-col cols="12" md="8" class="pa-md-13 pl-md-3 img">
              <v-img class="custom-round" eager
                src="@/assets/leaders_softino.jpg"
                max-width=734
              >
              </v-img>
            </v-col>
            <v-col cols="12" md="4" class="pa-md-13 pt-0 pb-0 pt-md-13 pb-md-13 pl-md-0">
              <v-row class="vertical-half">
                <v-col>
                  <h3><v-icon left dense color="white">mdi-account</v-icon>Barbora Šaldová</h3>
                  <div class="pb-3"><v-icon left dense color="white">mdi-label</v-icon><i>CEO</i></div>
                  <v-icon left dense color="white">mdi-phone-classic</v-icon><a class="white--text" href="tel:+420721960754">+420 721 960 754</a><br/>
                  <v-icon left dense color="white">mdi-email</v-icon><a class="white--text" href="mailto:barbora.saldova@softino.cz">barbora.saldova@softino.cz</a>
                  <div class="pt-3">
                    <v-icon left dense color="white">mdi-information</v-icon>
                    <v-dialog
                      v-model="dialogCeo"
                      width="80%"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a class="white--text" href="javascript:void(0)" v-bind="attrs" v-on="on">Informace</a>
                      </template>

                      <v-card class="pb-8">
                        <v-card-actions class="pb-0">
                          <v-spacer></v-spacer>
                          <v-btn
                            class="mt-2"
                            color="primary"
                            outlined
                            fab
                            small
                            @click="dialogCeo = false"
                          >
                            <v-icon>mdi-window-close</v-icon>
                          </v-btn>
                        </v-card-actions>

                        <v-row class="ma-0">
                          <v-col order="2" order-md="1" cols="12" md="4" class="py-0 pt-6 pt-md-0 px-10 pl-md-8 pr-md-6">
                            <v-img class="custom-circle"
                              src="@/assets/barbora_saldova.jpg"
                              max-width=600
                            >
                            </v-img>
                          </v-col>

                          <v-col order="1" order-md="2" cols="12" md="8" class="pa-0">

                            <v-card-title class="font-weight-bold primary--text pt-0">
                              <div v-html="infoBarbora.title"></div>
                            </v-card-title>

                            <v-card-subtitle class="">
                              <div v-html="infoBarbora.position"></div>
                            </v-card-subtitle>

                            <v-card-text>
                              <div v-html="infoBarbora.text"></div>
                            </v-card-text>
                            
                            <v-card-text>
                              <v-icon left dense color="primary">mdi-phone-classic</v-icon><a href="tel:+420721960754">+420 721 960 754</a><br/>
                              <v-icon left dense color="primary">mdi-email</v-icon><a href="mailto:barbora.saldova@softino.cz">barbora.saldova@softino.cz</a>
                            </v-card-text>

                          </v-col>
                        </v-row>

                      </v-card>
                    </v-dialog>
                  </div>
                </v-col>
              </v-row>
              <v-row class="vertical-half">
                <v-col>
                <h3><v-icon left dense color="white">mdi-account</v-icon>Michal Očko</h3>
                <div class="pb-3"><v-icon left dense color="white">mdi-label</v-icon><i>Delivery director</i></div>
                <v-icon left dense color="white">mdi-phone-classic</v-icon><a class="white--text" href="tel:+420724011579">+420 724 011 579</a><br/>
                <v-icon left dense color="white">mdi-email</v-icon><a class="white--text" href="mailto:michal.ocko@softino.cz">michal.ocko@softino.cz</a>
                <div class="pt-3">
                    <v-icon left dense color="white">mdi-information</v-icon>
                    <v-dialog
                      v-model="dialogDelivery"
                      width="80%"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a class="white--text" href="javascript:void(0)" v-bind="attrs" v-on="on">Informace</a>
                      </template>

                      <v-card class="pb-8">
                        <v-card-actions class="pb-0">
                          <v-spacer></v-spacer>
                          <v-btn
                            class="mt-2"
                            color="primary"
                            outlined
                            fab
                            small
                            @click="dialogDelivery = false"
                          >
                            <v-icon>mdi-window-close</v-icon>
                          </v-btn>
                        </v-card-actions>

                        <v-row class="ma-0">
                          <v-col order="2" order-md="1" cols="12" md="4" class="py-0 pt-6 pt-md-0 px-10 pl-md-8 pr-md-6">
                            <v-img class="custom-circle"
                              src="@/assets/michal_ocko.jpg"
                              max-width=600
                            >
                            </v-img>
                          </v-col>

                          <v-col order="1" order-md="2" cols="12" md="8" class="pa-0">

                            <v-card-title class="font-weight-bold primary--text pt-0">
                              <div v-html="infoMichal.title"></div>
                            </v-card-title>

                            <v-card-subtitle class="">
                              <div v-html="infoMichal.position"></div>
                            </v-card-subtitle>

                            <v-card-text>
                              <div v-html="infoMichal.text"></div>
                            </v-card-text>
                            
                            <v-card-text>
                              <v-icon left dense color="primary">mdi-phone-classic</v-icon><a href="tel:+420724011579">+420 724 011 579</a><br/>
                              <v-icon left dense color="primary">mdi-email</v-icon><a href="mailto:michal.ocko@softino.cz">michal.ocko@softino.cz</a>
                            </v-card-text>

                          </v-col>
                        </v-row>

                      </v-card>
                    </v-dialog>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-container>

      </div>

      <div class="regular mt-13">

        <v-container>

          <v-row id="sluzby">
            <v-col cols="4">
              <h2 class="font-weight-regular" v-html="servicesDescription.title"></h2>
            </v-col>
            <v-col cols="8">
              <div v-html="servicesDescription.text"></div>
            </v-col>
          </v-row>

          <v-row
            class="mt-10"
            v-match-heights="{el: ['.card-title']}"
          >

            <v-col
              v-for="(item, i) in services"
              :key="i"
              cols="12"
              sm="6"
              md="4"
            >
              <v-card
                outlined
                flat
              >
                <v-row class="ma-0">
                  <v-col cols="auto pr-1"><v-icon large left color="white" :class="'circle '+item.color">mdi-xml</v-icon></v-col>
                  <v-col class="pl-0"><v-card-title class="card-title pa-0" style="align-items:start">{{ item.title }}</v-card-title></v-col>  
                </v-row>
                <v-card-text class="card-text"><v-clamp autoresize :max-lines="6">{{ stripTags(item.text) }}</v-clamp></v-card-text>
                <v-card-actions class="pa-4 pt-0">
                  <v-dialog
                    v-model="dialogService[i]"
                    :max-width="size"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :class="'text-uppercase ml-auto border-'+item.color"
                        text
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        zjistěte více
                      </v-btn>
                    </template>

                    <v-card class="pb-8">
                      <v-card-actions class="pb-0">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mt-2"
                          color="primary"
                          outlined
                          fab
                          small
                          @click="dialogService = []"
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-card-title class="font-weight-bold primary--text pt-0 card-title">{{ item.title }}</v-card-title>
                      <v-card-text>
                        <div v-html="item.text"></div>
                      </v-card-text>
                    </v-card>

                  </v-dialog>
                </v-card-actions>
              </v-card>
            </v-col>
            
          </v-row>

        </v-container>

      </div>

      <div class="regular my-13 py-10 blue white--text">

        <v-container>

          <v-row>
            <v-col cols="12" md="4">
              <h2 class="font-weight-regular"><b>Zeptejte</b> se nás</h2>
            </v-col>
            <v-col cols="12" md="8">
              
              <form @submit.prevent="sendEmail" novalidate class="contact-form">
                <v-select
                  v-model="contact.select"
                  name="service"
                  :items="['Automatizace procesů', 'Templating', 'SAS Campaign Management', 'Řízení a digitální archivace dokumentů', 'Business Intelligence', 'Custom solutions', 'Hledám práci v tomto oboru', 'Mám jiný dotaz']"
                  :error-messages="selectErrors"
                  label="Jaký typ spolupráce hledáte"
                  multiple
                  chips
                  deletable-chips
                  required
                  @change="$v.contact.select.$touch()"
                  @blur="$v.contact.select.$touch()"
                ></v-select>
                <v-textarea
                  v-model="contact.note"
                  name="note"
                  :error-messages="noteErrors"
                  :counter="500"
                  label="Poznámka"
                  auto-grow
                  rows=4
                  @input="$v.contact.note.$touch()"
                  @blur="$v.contact.note.$touch()"
                ></v-textarea>
                <v-row>
                  <v-col class="pb-0" cols="12" md="6">
                    <v-text-field
                      v-model="contact.name"
                      name="name"
                      :error-messages="nameErrors"
                      label="Jméno a příjmení"
                      required
                      @input="$v.contact.name.$touch()"
                      @blur="$v.contact.name.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="6">
                    <v-text-field
                      v-model="company"
                      name="company"
                      label="Firma"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-0 pt-0 pt-sm-3" cols="12" md="6">
                    <v-text-field
                      v-model="contact.email"
                      name="email"
                      :error-messages="emailErrors"
                      label="E-mail"
                      required
                      @blur="$v.contact.email.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-0 pt-sm-3" cols="12" md="6">
                    <v-text-field
                      v-model="contact.phone"
                      name="phone"
                      :error-messages="phoneErrors"
                      label="Telefon"
                      required
                      @input="$v.contact.phone.$touch()"
                      @blur="$v.contact.phone.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pb-0" cols="12" md="6">
                    <vue-recaptcha 
                      ref="recaptcha"
                      @verify="onVerify"
                      @expired="onExpired"
                      sitekey="6LciUoQgAAAAAA2Kz7SrI6E7sW5lMtDpcrBKXaGz"
                      :loadRecaptchaScript="true"
                    >
                    </vue-recaptcha>
                    <div class="v-text-field__details mt-2">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="v-messages__message"><span v-if="validate && !robot">Ověření je povinné.</span></div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col class="pb-0 pt-0 pt-sm-3" cols="12" md="6" align-self="center">
                    <v-checkbox
                      color="white"
                      v-model="checkbox"
                      class="float-left mt-0"
                    ></v-checkbox>
                    <span>Souhlasím se <a href="javascript:void(0)" @click="dataPrivacyDialog = true" class="white--text">zpracováním osobních údajů</a></span>
                    <div class="v-text-field__details mt-2">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="v-messages__message"><span v-if="validate && !checkbox">Souhlas je povinný.</span></div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      class="border-white"
                      text
                      color="white"
                      type="submit"
                      @click="validateForm"
                    >
                      Odeslat
                    </v-btn>
                  </v-col>
                </v-row>
              </form>

              <v-dialog
                v-model="dataPrivacyDialog"
                :max-width="size"
              >
                <v-card>
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mt-2"
                      color="primary"
                      outlined
                      fab
                      small
                      @click="dataPrivacyDialog = false"
                    >
                      <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-card-title class="font-weight-bold primary--text pt-0 card-title">
                    <div v-html="GDPR.title"></div>
                  </v-card-title>
                  <v-card-text>
                    <div v-html="GDPR.text"></div>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="successDialog"
                max-width="500px"
              >
                <v-card>
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mt-2"
                      color="primary"
                      outlined
                      fab
                      small
                      @click="successDialog = false"
                    >
                      <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-card-title class="font-weight-bold primary--text pt-0 card-title">
                    Děkujeme za Váš dotaz
                  </v-card-title>
                  <v-card-text>
                    <p> Zpracujeme vše co nejdříve a budeme Vás kontaktovat.</p>
                    <p>Tým SOFTINO services s.r.o.</p>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="failedDialog"
                max-width="500px"
              >
                <v-card>
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mt-2"
                      color="primary"
                      outlined
                      fab
                      small
                      @click="failedDialog = false"
                    >
                      <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-card-title class="font-weight-bold primary--text pt-0 card-title">
                    Ooops! Něco se pokazilo
                  </v-card-title>
                  <v-card-text>
                    <p>Zkuste upravit informace ve formuláři. Potom to zkuste znovu.</p>
                    <p>Tým SOFTINO services s.r.o.</p>
                  </v-card-text>
                </v-card>
              </v-dialog>

            </v-col>
          </v-row>

        </v-container>

      </div>

    </v-main>

    <footer id="kontakt" class="mb-5">

      <v-container>

        <v-row>
          <v-col cols="12" md="4">

            <div>
              <v-img class="mb-5"
                src="@/assets/logo_softino.svg"
                max-width=130
                contain
              >
              </v-img>
            </div>

            <div>
            SOFTINO services s.r.o.<br/>
            Cyrilská 7<br/>
            602 00 Brno<br/>
            <a class="color--white" href="mailto:info@softino.cz">info@softino.cz</a>
            </div>

            <div>
              <v-dialog
                v-model="dialogCompany"
                :max-width="size"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mt-5"
                    outlined
                    color="blue"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    Úplné kontaktní údaje
                  </v-btn>
                </template>

                <v-card class="pb-8">
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mt-2"
                      color="primary"
                      outlined
                      fab
                      small
                      @click="dialogCompany = false"
                    >
                      <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-card-title class="card-title font-weight-bold primary--text pt-0">
                    Úplné kontaktní údaje
                  </v-card-title>
                  <v-card-text>
                    <v-icon left dense color="primary">mdi-domain</v-icon>SOFTINO services s.r.o.<br/>
                    <v-icon left dense color="primary">mdi-map-marker</v-icon>Cyrilská 508/7, 602 00 Brno<br/><br/>
                    <v-icon left dense color="primary">mdi-email</v-icon><a href="mailto:info@softino.cz">info@softino.cz</a><br/>
                    <v-icon left dense color="primary">mdi-phone-classic</v-icon><a href="tel:+420 721 960 754">+420 721 960 754</a><br/><br/>
                    <v-icon left dense color="primary">mdi-file</v-icon>IČ: 14277352<br/>
                    <v-icon left dense color="primary">mdi-file-multiple</v-icon>DIČ: CZ14277352<br/>
                    <v-icon left dense color="primary">mdi-gavel</v-icon>Zapsáno v obchodním rejstříku u Krajského soudu v Brně pod spisovou značkou C 127566
                  </v-card-text>
                </v-card>

              </v-dialog>
            </div>

          </v-col>
          <v-col cols="12" md="8">
            <v-row>
              <v-col
                v-for="(item, i) in services"
                :key="i"
                cols="12"
                sm="6"
              >
                <v-icon dense left color="white" 
                :class="'circle ' + item.color">mdi-xml</v-icon> {{ item.title }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-container>

    </footer>

  </v-app>
</template>

<script>
import VClamp from 'vue-clamp'
import emailjs from 'emailjs-com'
import VueRecaptcha from 'vue-recaptcha'
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import axios from 'axios';
import { reactive, ref } from 'vue';

export default {
  name: 'App',

  mixins: [validationMixin],
  validations: {
    contact: {
      select: { required },
      note: { maxLength: maxLength(500) },
      name: { required },
      email: { required, email },
      phone: { required },
    }
  },

  components: {
    VClamp,
    VueRecaptcha,
  },

  metaInfo() {
    return {
      title: 'Jedinečná softwarová řešení pro váš rozvoj a růst. | SOFTINO',
      description: 'Softino - to jsou lidé, kteří znají jedinečná a spolehlivá softwarová řešení. Přispívají k rozvoji a růstu každého klienta. Spolupráce s nimi je dlouhodobá, vysoce důvěrná a velmi exkluzívní.',
      meta: [
        {name: 'robots', content: 'index, follow'
        },
      ]
    }
  },

  data: () => ({
    contact: {
      select: null,
      note: '',
      name: '',
      email: '',
    },
    validate: false,
    company: '',
    phone: '',
    recaptcha: false,
    checkbox: false,
    robot: false,
    dataPrivacyDialog: false,
    successDialog: false,
    failedDialog: false,
    dialogCeo: false,
    dialogDelivery: false,
    dialogService: [],
    dialogCompany: false,
    menuItems: [
      { title: 'O nás', anchor: 'o-nas' },
      { title: 'Náš tým', anchor: 'nas-tym' },
      { title: 'Služby', anchor: 'sluzby' },
      { title: 'Kontakt', anchor: 'kontakt' },
    ],
    services: [],
    leadBox: {
        title: '',
        content: ''
    },
    about: {
        title: '',
        content: ''
    },
    servicesDescription: {
        title: '',
        content: ''
    },
    infoMichal: {
        title: '',
        position: '',
        content: ''
    },
    infoBarbora: {
        title: '',
        position: '',
        content: ''
    },
    GDPR: {
        title: '',
        content: ''
    },
  }),

  computed: {
    size () {
      const size = {xs:'90%',sm:'80%',md: '70%', lg:'60%',xl:'50%'}[this.$vuetify.breakpoint.name];
      return size ? size : ''
    },
    selectErrors() {
      const errors = []
      if (!this.$v.contact.select.$dirty) return errors
      !this.$v.contact.select.required && errors.push('Vybrat alespoň jednu službu je povinné.')
      return errors
    },
    noteErrors() {
      const errors = []
      if (!this.$v.contact.note.$dirty) return errors
      !this.$v.contact.note.maxLength && errors.push('Poznámka může obsahovat maximálně 500 znaků.')
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.contact.name.$dirty) return errors
      !this.$v.contact.name.required && errors.push('Jméno a příjmení jsou povinné.')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.contact.email.$dirty) return errors
      !this.$v.contact.email.email && errors.push('E-mailová adresa musí být ve správném formátu.')
      !this.$v.contact.email.required && errors.push('E-mailová adresa je povinná.')
      return errors
    },
    phoneErrors() {
      const errors = []
      if (!this.$v.contact.phone.$dirty) return errors
      !this.$v.contact.phone.required && errors.push('Telefon je povinný.')
      return errors
    },
  },

  methods: {
    onVerify: function(response) {
      if (response) this.robot = true
    },
    onExpired: function() {
      this.robot = false
    },
    validateForm() {
      this.validate = true
      this.$v.contact.$touch()
    },
    sendEmail: function(e) {
      if (!this.$v.contact.$invalid && this.robot && this.checkbox) {
        emailjs.sendForm('service_76joi7a', 'template_gucswfj', e.target, 'lTenz2S6nXx9blbzq')
          .then(() => {
              this.successDialog = true
          }, () => {
              this.failedDialog = true
          });
          this.$refs.recaptcha.reset()
          this.$v.contact.$reset()
          this.contact.select = null,
          this.contact.note = ''
          this.contact.name = ''
          this.contact.email = ''
          this.company = ''
          this.phone = ''
          this.recaptcha = false
          this.checkbox = false
          this.validate = false
          this.robot = false
      }
    },

    stripTags(string) {
      return string.replace(/<\/?[^>]+(>|$)/g, "")
    },

    leftCircle() {
      
      setTimeout(() => {
        let windowWidth = document.body.clientWidth;

        if (windowWidth > 960) {
          let windowWidth = document.body.clientWidth;
          let circleLeftWidth = document.querySelector('.circle-left > .container').offsetWidth;
          let offsetContainer = windowWidth - circleLeftWidth;
          let result = offsetContainer / 4;
          let circleLeftHeight = document.querySelector('.circle-left .text-container').offsetHeight;

          if ((circleLeftHeight / 2) <= result) {
            document.querySelector('.circle-left').style.marginLeft = Math.ceil(result) + 'px';
            document.querySelector('.circle-left > .container').style.marginLeft = Math.ceil(result) + 'px';
          }
          else {
            document.querySelector('.circle-left').style.marginLeft = Math.ceil(((offsetContainer / 2) - (circleLeftHeight / 2))) + 'px';
            document.querySelector('.circle-left > .container').style.marginLeft = Math.ceil(circleLeftHeight / 2) + 'px';
          }
          
          document.querySelector('.circle-left').style.borderTopLeftRadius =  Math.ceil(circleLeftHeight / 2) + 'px';
          document.querySelector('.circle-left').style.borderBottomLeftRadius =  Math.ceil(circleLeftHeight / 2) + 'px';
          document.querySelector('.background-xml').style.height =  circleLeftHeight + 'px';
          document.querySelector('.background-xml').style.width =  circleLeftHeight + 'px';
        }
        else {
          document.querySelector('.circle-left').style.marginLeft = '0px';
          document.querySelector('.circle-left > .container').style.marginLeft = '0px';
          
          let circleLeftHeight = document.querySelector('.circle-left .text-container').offsetHeight;

          document.querySelector('.circle-left').style.borderTopLeftRadius =  '0px';
          document.querySelector('.circle-left').style.borderBottomLeftRadius =  '0px';
          document.querySelector('.background-xml').style.height =  circleLeftHeight + 'px';
          document.querySelector('.background-xml').style.width =  circleLeftHeight + 'px';
        }

      }, 300);
    },

    rightCircle() {
      let windowWidth = document.body.clientWidth;

      if (windowWidth > 960) {
        let circleRightWidth = document.querySelector('.circle-right > .container').offsetWidth;
        let offsetContainer = windowWidth - circleRightWidth;
        let result = offsetContainer / 4;
        let circleRightHeight = document.querySelector('.circle-right .row > .img').offsetHeight;

        if ((circleRightHeight / 2) <= result) {
          document.querySelector('.circle-right').style.marginRight = Math.ceil(result) + 'px';
          document.querySelector('.circle-right > .container').style.marginRight = Math.ceil(result) + 'px';
        }
        else {
          document.querySelector('.circle-right').style.marginRight = Math.ceil(((offsetContainer / 2) - (circleRightHeight / 2))) + 'px';
          document.querySelector('.circle-right > .container').style.marginRight = Math.ceil(circleRightHeight / 2) + 'px';
        }
        
        document.querySelector('.circle-right').style.borderTopRightRadius =  Math.ceil(circleRightHeight / 2) + 'px';
        document.querySelector('.circle-right').style.borderBottomRightRadius =  Math.ceil(circleRightHeight / 2) + 'px';
      }
      else {
        document.querySelector('.circle-right').style.marginRight = '0px';
        document.querySelector('.circle-right > .container').style.marginRight = '0px';
        document.querySelector('.circle-right').style.borderTopRightRadius =  '0px';
        document.querySelector('.circle-right').style.borderBottomRightRadius = '0px';
      }
    },
    feed() {
      const r = axios.get('/content-data.json', {
        responseType: 'json'
      }).then((r) => {
        this.services = r.data.services;
        this.leadBox = r.data.leadBox;
        this.about = r.data.about;
        this.servicesDescription = r.data.servicesDescription;
        this.infoMichal = r.data.infoMichal;
        this.infoBarbora = r.data.infoBarbora;
        this.GDPR = r.data.GDPR;
      });
    },
  },

  created() {
    window.addEventListener("resize", this.leftCircle);
    window.addEventListener("resize", this.rightCircle);
  },

  mounted() {
    this.leftCircle();
    setTimeout(this.rightCircle, 100);
    this.feed();
  },

  destroyed() {
    window.removeEventListener("resize", this.leftCircle);
    window.removeEventListener("resize", this.rightCircle);
  },

};
</script>

<style scoped lang="scss">

  .title {
    line-height: 2.5rem !important;
  }

  .block {
    display: block;
  }

  .background-xml {
    font-size: 200px !important;
    background: #fff;
    border-radius: 50%;
    opacity: 0.3;
  }

  .circle {
    border-radius: 50%;
  }

  .circle-left, .circle-right {
    background: var(--v-primary-base);
  }

  .custom-round {
    border-radius: 20px !important;
  }

  .custom-circle {
    border-radius: 50% !important;
    border: 1px solid var(--v-primary-base);
  }

  .vertical-half {
    height: 50%;
  }

  .card-title {
    word-break: break-word;
  }

  .border-white {
    border-bottom: 2px solid white;
  }

  .border-yellow {
    border-bottom: 2px solid var(--v-yellow-base);
  }

  .border-green {
    border-bottom: 2px solid var(--v-green-base);
  }

  .border-purple {
    border-bottom: 2px solid var(--v-purple-base);
  }

  .border-orange {
    border-bottom: 2px solid var(--v-orange-base);
  }

  .border-blue {
    border-bottom: 2px solid var(--v-blue-base);
  }

  .border-pink {
    border-bottom: 2px solid var(--v-pink-base);
  }

  .v-dialog__content .theme--light.v-card > .v-card__text, .v-dialog__content .theme--light.v-card > .v-card__subtitle {
    color: rgba(0, 0, 0, 0.87);
  }
  .w-menu {
    img {
      height: 25px;
    }
  }
</style>

<style>

  .contact-form .theme--light.v-label, .contact-form .theme--light.v-icon, .theme--light.v-counter, .contact-form .theme--light.v-input input, .contact-form .theme--light.v-input textarea {
    color: rgba(256, 256, 256, 1) !important;
  }

  .contact-form .theme--light.v-label.v-label--active {
    color: rgba(256, 256, 256, 1) !important;
  }

  .contact-form .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: rgba(256, 256, 256, 1) !important;
  }

  .contact-form .v-text-field > .v-input__control > .v-input__slot:after {
    border-color: rgba(256, 256, 256, 1) !important;
    background-color: rgba(256, 256, 256, 1) !important;
  }

  .v-application .contact-form .error--text {
    color: var(--v-yellow-base) !important;
    caret-color: var(--v-yellow-base) !important;
    font-weight: bold !important;
  }

  .w-f {
    width: 170px !important;
    height: 170px !important;
    margin-top: 50px;
  }
</style>
